import React from "react"
import { Link, graphql } from "gatsby"
//import kebabCase from "lodash/kebabCase"

import {Bio, SEO, BlogCTA} from "../components"
import {BlogLayout} from "../layouts"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const image = post.frontmatter.image
      ? post.frontmatter.image.childImageSharp.resize
      : null
    //console.log(SEO);
    const styles = {};
    if(post.frontmatter.hero){
      styles.backgroundImage = `url(${post.frontmatter.hero})`;
    }
    return (
      <BlogLayout location={this.props.location} title={siteTitle}>
        <SEO
          lang={post.fields.language}
          meta={post.frontmatter.meta}
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
         // image={post.frontmatter.hero}
          image={image}
          // image="https://www.duuers.com/images/duuers-logo-some.jpg"
          pathname={this.props.location.pathname}
          link={post.frontmatter.link}
        />
        <section className="content_11 padding_bottom50 blog-post" style={styles}>
    	    <div className="container nopadding top250 max_width970">
        			<div className="margin_auto top25 font18 light dark_blue text bg_white padding_left25 padding_right25 padding_top50">
        				{post.headings[0] && <h1
                  style={{
                    marginBottom: '0.5em',
                  }}
                >
                  {post.headings[0].value}
                </h1>
        				}
                <Bio frontmatter={post.frontmatter}/>
               {/* <p>
                  {post.frontmatter.date}
                </p> */}
                <div className="blog-post__content" dangerouslySetInnerHTML={{ __html: post.html }} />
                <BlogCTA/>
                <hr/>
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link to={`${previous.fields.path}`} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={`${next.fields.path}`} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
        			</div>
        	</div>
        </section>
      </BlogLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        language
      }
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
        description
        meta {
          name
          content
        }
        link {
          rel
          href
          hreflang
        }
        hero
      }
      headings(depth: h1) {
        value
      }
    }
  }
`
